<script setup>

</script>

<template>

  <div class="socialBar">
      <v-btn
      icon="mdi-facebook"
      variant="plain"
      size="x-large"
      block
      rounded="sm"
      target="_blank"
      href="https://www.facebook.com/DANDGDUMPSTERS"
      >
      </v-btn>
    <v-btn
        icon="mdi-instagram"
        variant="plain"
        size="x-large"
        block
        rounded="sm"
        target="_blank"
        href="https://www.instagram.com/d_and_g_dumpsters/?igsh=MWl4b3NxcHJnMHBjOA%3D%3D"
    >

    </v-btn>
      <v-btn
          icon="mdi-google"
          variant="plain"
          size="sm"
          block
          rounded="sm"

      ></v-btn>

  </div>


</template>

<style scoped>

.socialBar {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5%;
}

</style>