<script setup>



</script>

<template>
  <div id="faq-section" class="faq-section">
    <h1>Frequently Asked Questions</h1>
    <v-expansion-panels>
    <v-expansion-panel
        title="Can we move the dumpster?"
        text="If you need the dumpster relocated after initial delivery call the service number and we'll come out and
relocate the dumpster to your new desired location for an additional charge.">
    </v-expansion-panel>

    <v-expansion-panel
        title="Is there anything I can't put in the dumpster?"
        text="No hazardous materials such as paint, oil, gasoline etc. no large amounts of dirt, sand, gravel, concrete or items containing freon.
      Tires can be put in the dumpster for an additional charge."
        >
    </v-expansion-panel>

    <v-expansion-panel
        title="Will the customer be responsible for dumping?"
        text="No! D&G Dumpsters will retrieve the dumpster and take it to the appropriate landfill or recycling facility.">
    </v-expansion-panel>

    <v-expansion-panel
        title="Do I need to pick the dumpster up?"
        text="No! D&G Dumpsters will deliver the dumpster to your preferred location.">
    </v-expansion-panel>

      <v-expansion-panel
          title="Is the dumpster safe for my driveway?"
          text="Yes, all our dumpsters are driveway safe.">
      </v-expansion-panel>

      <v-expansion-panel
          title="Can I set up a recurring dumpster delivery?"
          text="Yes, we've worked with clients before that have needs for scheduled regular dumpster delivery. Give us
                a call and we can discuss your specific needs.">
      </v-expansion-panel>

      <v-expansion-panel
      title="How much does junk removal cost?"
      text="Every junk removal job is different, call us and we will give you an estimate for our services.">

      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>

<style scoped>

.faq-section {
  font-size: x-large;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 20%;
  padding-bottom: 5%;
}

.faq-section h1 {
  font-size: xxx-large;
}

</style>